<template>
    <ion-content :fullscreen="true">
        <ion-page>
            <!--<ion-list>-->
            <!--    <ion-list>-->
            <!--        <ion-item>-->
            <!--            <ion-label>Input</ion-label>-->
            <!--            <ion-input></ion-input>-->
            <!--        </ion-item>-->
            <!--        <ion-item>-->
            <!--            <ion-label>Toggle</ion-label>-->
            <!--            <ion-toggle slot="end"></ion-toggle>-->
            <!--        </ion-item>-->
            <!--        <ion-item>-->
            <!--            <ion-label>Radio</ion-label>-->
            <!--            <ion-radio slot="end"></ion-radio>-->
            <!--        </ion-item>-->
            <!--        <ion-item>-->
            <!--            <ion-label>Checkbox</ion-label>-->
            <!--            <ion-checkbox slot="start"></ion-checkbox>-->
            <!--        </ion-item>-->
            <!--    </ion-list>-->
            <!--</ion-list>-->
            
            <!--<div class="map-list">-->
            <!--    <div>-->
            <!--        <ion-list>-->
            <!--            <AdminPointsItem-->
            <!--                v-for="(point, index) in data"-->
            <!--                :key="index"-->
            <!--                :coords="point.coords"-->
            <!--                :desc="point.desc"-->
            <!--                :name="point.name"-->
            <!--                :place="point.place"-->
            <!--            />-->
            <!--        </ion-list>-->
            <!--    </div>-->
            <!--</div>-->
            
            
            <ion-list>
                <ion-item
                    v-for="(placemark, index) in data"
                    :key="index"
                    :index="index"
                    button
                    detail
                    style="cursor: pointer;"
                >
                    <ion-label>
                        <h2>{{ placemark.name }}</h2>
                        <h3>{{ placemark.place }}</h3>
                        <p>{{ placemark.desc }}</p>
                    </ion-label>
                    
                    <ion-button
                        fill="clear"
                    >
                        <img
                            slot="icon-only"
                            alt="Информация"
                            height="25"
                            src="../../../public/assets/images/icons8-info.svg"
                            width="25"
                        >
                    </ion-button>
                </ion-item>
            </ion-list>
        </ion-page>
    </ion-content>
</template>

<script>
// import AdminPointsItem from './AdminPointsItem';

export default {
    name:       'AdminPoints',
    components: {
        // AdminPointsItem
    },
    setup() {
        const data = [
            {
                '_id':      '6165571c01dcf4f4bbe12bab',
                'name':     'Храм во имя святого Иоанна Предтечи и Крестителя Господня',
                'desc':     'Первый храм в селе Башкарка был деревянный, построен в 1879 году. В 1888 году во время большого пожара, когда выгорело почти всё село, храм сгорел. 1890 году был заложен каменный однопрестольный Иоанно-Предтеченский храм; строился он на средства прихожан при пособии епархиального начальства и земства губернского и уездного, окончен и освящен был с благословения Преосвященного Афанасия, епископа Екатеринбургского и Ирбитского в 1893 году.',
                'place':    'с. Башкарка, ул. Красной  Молодежи, 18',
                'isActive': true,
                'img':      '',
                'coords':   [
                    57.62639,
                    60.882276,
                ],
                'id':       '71ba1391-be58-4754-b5af-35e6ee6cef91',
            },
            {
                '_id':      '61655abb03ea2b3a797ce1f0',
                'coords':   [
                    57.27192775621621,
                    60.14896799999996,
                ],
                'id':       '37c3f182-76ad-44d3-9020-bf27e20cd1f8',
                'name':     'Ивановский рудник. Бродовские пещеры',
                'place':    'с. Бродово',
                'desc':     'Между с. Бродово и д. Дубасова, по правую сторону реки Бродовки находится Ивановский рудник. Руда была найдена здесь в 1744 году, разрабатывался рудник до 1905 года. По сведениям, за 1901 год на Ивановском руднике было добыто 40 тысяч пудов руды. С рудника ее доставляли на Петрокаменский и Невьянский заводы на лошадях через речку вброд. Отсюда и название реки - Бродовка, а села - Бродово. На месте старых выработок образовались пещеры. Эти места до сих пор привлекают туристов.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167bb3642d8b24dbebaaf32',
                'coords':   [
                    57.671802,
                    60.59522,
                ],
                'id':       '1613b376-6e45-41ae-8980-044bcb5a1ec5',
                'name':     'Бродовский дом культуры',
                'place':    'с. Бродово, ул. Мира, 29А',
                'desc':     'Основное здание Дома культуры было построено в 1890-х годах. Первоначально это был Меценатский дом, посетить который могли только богатые люди. \nВ 1917 году к зданию сделали пристрой. Его строили\n пленные австрийцы. После революции в здании был\n расположен Народный дом. На сцене ставил постановки Народный театр, был организован оркестр. \nНародный дом просуществовал до 1941 года. С 1946 \nгода в здании располагается сельский клуб.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167c18a42d8b24dbebaaf33',
                'coords':   [
                    57.671801,
                    60.59522,
                ],
                'id':       'f69b076b-6a05-4b99-8518-ba36dc4b04ec',
                'name':     'Здание Бродовской волости',
                'place':    'с. Бродово, ул. Мира, 31',
                'desc':     'Здание Бродовской волости было построено в 1850 году, но вскоре оно сгорело. Спустя два года на этом \nже месте было построено новое двухэтажное здание.\nПервый этаж кирпичный, а второй деревянный, на \nкрыше здания пожарная каланча.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167c33e42d8b24dbebaaf34',
                'coords':   [
                    57.67122473110947,
                    60.591758971840136,
                ],
                'id':       'd7752f81-e49d-49ef-8d8a-5c51974be89a',
                'name':     'Памятник «Воинам, погибшим в  годы Великой Отечественной войны»',
                'place':    'с. Бродово, ул. Мира 31А, парк дома культуры',
                'desc':     'Памятник установлен к 50-летию Победы в Великой Отечественной войне. На плитах высечены 93 фамилии жителей села, погибших в сражениях за Родину.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167c43b42d8b24dbebaaf35',
                'coords':   [
                    57.65083294289708,
                    59.49824372883602,
                ],
                'id':       'f7c55a0d-e2b1-4df5-8e26-1e0e110f8351',
                'name':     'Литературно-мемориальный музей  Д.Н. Мамина-Сибиряка',
                'place':    'п. Висим, ул. Д.Н. Мамина-Сибиряка, 9 ',
                'desc':     'До наших дней сохранился дом, построенный в 40-е \nгоды XIX века, в котором прошли детские и юношеские годы писателя. Деревянный дом на каменном \nфундаменте, под железной крышей. Обстановка \nдома, предметы быта семьи священника Н.М. Мамина показывают условия, в которых формировался \nталант писателя. Дом, где родился и провёл детские\nгоды писатель Д.Н. Мамин-Сибиряк, является объектом культурного наследия регионального значения.\n+73435370201 доб. 388, +79090225279',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167c56542d8b24dbebaaf36',
                'coords':   [
                    57.65033684364481,
                    59.49831883068845,
                ],
                'id':       '8a9e93e0-cf8a-41b6-b7e0-abb912b5857d',
                'name':     'Здание, в котором учился  Д.Н. Мамин-Сибиряк',
                'place':    'п. Висим, ул. Д.Н. Мамина-Сибиряка, 7',
                'desc':     'Здание заводской церковно-приходской школы, в которой постигал азы науки будущий писатель в 1860-1863 гг., находилось рядом с его родным домом. Первая школа в поселке была открыта при Анатолиевской церкви в 1839 году и находилась в ведении дирекции народных училищ. С 30 октября 2002 года здесь открыт музей церковно-приходской  школы литературно-мемориального музея Д.Н. Мамина-Сибиряка. Здание является объектом культурного наследия федерального значения.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167c62942d8b24dbebaaf37',
                'coords':   [
                    57.64943953379156,
                    59.49822227116387,
                ],
                'id':       '282125d6-5297-4d50-89a6-960c18118546',
                'name':     'Памятник-бюст писателю  Д.Н. Мамину-Сибиряку',
                'place':    'п. Висим, ул. Ленина, Аллея Славы',
                'desc':     'Большой интерес представляют исторические и \nмемориальные объекты, расположенные в Висиме. Кроме музея Д. Н. Мамина - Сибиряка, в Висиме установлен и памятник «певцу Урала», открытый в 1962 году.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167c71c42d8b24dbebaaf38',
                'coords':   [
                    57.649128,
                    59.468112,
                ],
                'id':       '8e3060b3-42d8-44e6-97d3-26f5a7975859',
                'name':     'Гора Кокурникова',
                'place':    'п. Висим',
                'desc':     'Гора Кокурникова - одна из гор, расположенная в западной части Висима. Со смотровой площадки, распложенной на вершине горы, открывается живописный вид на посёлок и висимские горы Пугина, \nМалая и Большая Шульпиха, Белая, Седло, Осиновая, Широкая, Билимбай и Старик - Камень. Здесь в 2002 году установили мемориальный камень к 150-летию со дня рождения уральского писателя Д.Н. Мамина-Сибиряка. В детстве будущий\nписатель часто любовался окрестностями Висима с\n вершины Кокурниковой.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167c77442d8b24dbebaaf39',
                'coords':   [
                    57.64990679584021,
                    59.497484384180616,
                ],
                'id':       '91a8568c-f72d-4e34-8277-78967ed0be7a',
                'name':     'Храм во имя святителя Николая  Чудотворца',
                'place':    'п. Висим, ул. Ленина, 3',
                'desc':     'Каменная однопрестольная Анатолие-Николаевская\n церковь в поселке Висим заложена в 1889 году и \nосвящена в 1895 году. Церковь построили вместо \nобветшавшего деревянного Анатолиевского храма, \nпоставленного еще в 1839 году. Новый каменный \nхрам построили в византийском стиле по проекту \nизвестного уральского архитектора Сергея Козлова. \nНовый храм имел редкую на Среднем Урале центрическую планировку в форме креста, а не вытянутую «кораблем».',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167c97842d8b24dbebaaf3a',
                'coords':   [
                    57.65202906698206,
                    59.50228449999997,
                ],
                'id':       '020519a7-b3dc-4660-bc59-e69546526955',
                'name':     'Музей быта и ремёсел п. Висим',
                'place':    'п. Висим,  ул. Октябрьская, 1 ',
                'desc':     'Музей расположен в старинном здании деревянной\n постройки XIX века. Здесь можно увидеть предметы \nбыта населения среднего Урала, предметы производства Висимо-Шайтанского железоделательного \nзавода, инструменты старателей, изделия кузнечного, столярного, жестяного и других ремесел. \nВ музее вы узнаете, как жили наши предки. Познакомитесь с особенностями жизни представителей \nтрёх этнографических концов - кержаков, туляков и \nукраинцев.\n+73435917223\nmk-ggo.ru\nСр-вс: с 9 до 17 час., пн-вт: выходной',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167c9a442d8b24dbebaaf3b',
                'coords':   [
                    57.64028656698232,
                    59.50470999999991,
                ],
                'id':       'e12e85d9-f395-497b-b46e-8c4ec0cede80',
                'name':     'Дом семьи Черепановых',
                'place':    'п. Висим, ул. Калинина, 64',
                'desc':     'У дома Черепановых гостей встречают ворона и лисица из знаменитой басни Крылова, сидящая на ветвях пушкинская русалка, Иван-царевич из сказки о молодильных яблоках. Все скульптуры деревянные, но сделаны так искусно, что кажутся живыми.\nПосле выхода на пенсию Черепановы купили в поселке дом, и благодаря таланту Виталия Николаевича и Нины Михайловны превратили его в сказочную усадьбу. Работы Виталия Николаевича постоянно выставляются в музее, а на территорию усадьбы приезжают гости со всех концов ',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167cad442d8b24dbebaaf3c',
                'coords':   [
                    57.654087,
                    59.534235,
                ],
                'id':       '5dc95098-d045-4423-8223-49ea6575a551',
                'name':     'Висимская зооферма',
                'place':    'Справа от трассы, напротив п. Висим ',
                'desc':     'Ферма располагается на территории около 100 гектар. Первыми были завезены маралы. Позже на ферме появились якутские лошадки, страусы, кролики, индюки, цесарки, яки, пятнистые олени, аль\nпаки и другие животные. Возможность увидеть животных и покормить их из рук привлекает взрослых\nи детей со всей области.\n+79222259061.\nвыходные и праздничные дни с 11.00 до 17.00, будние дни по предварительной заявке.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167cb2942d8b24dbebaaf3d',
                'coords':   [
                    57.736575,
                    59.531247,
                ],
                'id':       'f24a26bf-92c9-43b7-8bf0-f745250d80ef',
                'name':     'Озеро Бездонное',
                'place':    '10 километров к северу от окраины п. Висим координаты ',
                'desc':     'Озеро представляет собой небольшой водоем округлой формы диаметром примерно 200 м. Площадь \nоколо 3 гектар. При своих небольших размерах максимальная глубина озера достигает 49 метров, а \nсредняя глубина – 25 метров. Берег резко обрывается на большую глубину. \nОзеро является гидрологическим памятником при\nроды и имеет статус охраняемой территории областного значения.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167cb8842d8b24dbebaaf3e',
                'coords':   [
                    57.647222,
                    58.973889,
                ],
                'id':       '2914a127-4cbc-4fe6-a40f-d16a5fb9de8a',
                'name':     'Памятник Акинфию Демидову',
                'place':    'д. Харёнки',
                'desc':     'Конная статуя установлена в 2009 году на острове посреди Чусовой, в начале деревни Харенки. Величественный конный памятник уральскому промышленнику и меценату Акинфию Демидову встречает \nна водном туристическом сплаве по Чусовой. Автор \nпамятника - известный российский скульптор \nКонстантин Грюнберг. Роль династии Демидовых в \nистории Урала велика, недаром надпись на памятнике гласит «Акинфию Демидову – благодарные \nпотомки»',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167cbbd42d8b24dbebaaf3f',
                'coords':   [
                    57.474810696613076,
                    59.49539399999992,
                ],
                'id':       '690d183f-13f4-4a09-ab3c-abaeb5e758cd',
                'name':     'Часовня в честь св. Троицы',
                'place':    'с. Большие Галашки',
                'desc':     'Деревня Большие Галашки основана в 1594 году казаками. В дальнейшем она получила известность своими старообрядческими скитами. Через деревню \nпроходили дороги с казенных и демидовских заводов до пристани на реке Чусовой, куда привозили \nпродукцию для отправки в Центральную Россию. \nСейчас в деревне, расположенной в заповедной \nзоне, обустроены подворье Архангельского храма \nс часовней и казачий курень Верхнесулёмский хутора «Архангельский».',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167cc3842d8b24dbebaaf40',
                'coords':   [
                    58.0185,
                    59.881,
                ],
                'id':       '843d95c0-655e-45ce-8b68-326bd102b06c',
                'name':     'Медведь-камень. Ермаково Городище',
                'place':    'Евстюниха',
                'desc':     'Гора Медведь-камень с окружающими лесами является памятником природы Свердловской области.\nМаксимальная высота скалы составляет 288 м. С \nвершины Медведь-камня открывается красивейший \nвид на окрестности: вьющуюся внизу реку, зеленые \nполянки, тайгу, горные цепи. Не менее интересная \nдостопримечательность находится на противоположном берегу реки Тагил. «Ермаково городище» - \nоткрытая в наше время стоянка Ермака на песчаной\n террасе р. Тагил. Является объектом археологического наследия.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167cd0c42d8b24dbebaaf41',
                'coords':   [
                    58.020639,
                    59.866306,
                ],
                'id':       'ed4abe76-3114-41f9-ab55-908fe639d454',
                'name':     'Этнопарк «Ермаково-городище»',
                'place':    'Евстюниха',
                'desc':     'Парк расположен в живописном месте - недалеко от \nг. Нижний Тагил, у подножья горы Медведь-камень, \nмежду поселками Евстюниха и Горноуральский. На \nтерритории этнопарка представлены архитектура, \nнациональная кухня, ремесла, традиции и быт народов, проживающих на Урале в XVI веке. Здесь \nможно пройти нехожеными лесными тропами, по\nзнакомиться с самобытной культурой и традициями,\nприобрести сувениры на любой вкус.\n+79827497411, +79222187422\nEжедневно с 10 до 19 час.\nermakgorod.ru',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167cd5642d8b24dbebaaf42',
                'coords':   [
                    58.059707066504664,
                    59.926603499999906,
                ],
                'id':       'df6798cb-f8d0-48f8-8bc2-261d1a1417c2',
                'name':     'Храм во имя святой равноапостольной  Марии Магдалины',
                'place':    'с. Лая, ул. Ленина, 1',
                'desc':     'Однопрестольная церковь построена на средства прихожан, представляет собой восьмерик под крупным световым барабаном с граненым куполом и\nглавкой, с небольшой трапезной и ярусной колокольней. Построили церковь всего на три года (1900-1903). В советские годы, несмотря на попытки \nвластей закрыть церковь, храм всегда оставался \nдействующим. До наших дней без больших изменений сохранился интерьер храма.\n+73435912102',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167cdba42d8b24dbebaaf43',
                'coords':   [
                    58.06273127784047,
                    59.914798800740954,
                ],
                'id':       '911e8dc4-7798-47ed-91b5-71aa52cfbd0f',
                'name':     'Демидовские заводские плотины',
                'place':    'с. Лая',
                'desc':     'В селе находилось два железоделательных завода - Нижний Лайский и Верхний Лайский. Расстояние между ними небольшое, примерно километр. При каждом находились молотовые с тремя молотами и двумя двойными горнами, кузницы и амбары. Лайские заводы просуществовали до начала XX века, а \nих продукция неоднократно выставлялась на российских ярмарках и международных выставках. \nТеперь о Нижнем напоминает сохранившаяся плотина с прудом, возле которой стоял завод, а от \nВерхнего следов почти не осталось.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167ce3b42d8b24dbebaaf44',
                'coords':   [
                    58.12040646002235,
                    59.8769999708862,
                ],
                'id':       'a3a908a7-7f9d-435e-8203-697454d9504e',
                'name':     'Стела памяти погибших мотоциклистов',
                'place':    'с. Малая Лая, 171 км. трассы «Екатеринбург – Серов»',
                'desc':     'Единственный в области памятник погибшим байке\nрам, выполнен в виде металлической опоры, которую \nвенчает парящий белый ангел на мотоцикле, а внизу \nвисит колокол, в который можно ударить в память о \nпогибшем мотоциклисте. Поставить стелу придумали байкеры из Лесного. Идею поддержали сначала участники мотоклубов в Кушве и Нижнем Тагиле, а \nзатем мотоциклисты из разных уголков России. Открытие памятника погибшим мотоциклистам состоялось в 2017 году.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167ce8442d8b24dbebaaf45',
                'coords':   [
                    57.71522406690089,
                    60.47746849999996,
                ],
                'id':       '809ff095-99fb-45af-95bc-a94fa9695cda',
                'name':     'Храм во имя Рождества Пресвятой Богородицы',
                'place':    'с. Краснополье, ул. Горная, 3,',
                'desc':     'Храм является объектом культурного наследия регионального значения. Был заложен в 1820 г. по указу Преосвященного Иустина, Епископа Пермского и Екатеринбургского и строился на средства на-\nрода. Закончилось строительство в 1828 г. Снаружи\n был отштукатурен, внутри расписан масляными \nкрасками, обнесен красивой, железной изгородью. \nСтоит храм на берегу р. Вилюй. Главной храмовой\n иконой является - икона Рождества Пресвятой Богородицы.\n+79533897575',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167ceb342d8b24dbebaaf46',
                'coords':   [
                    57.7187185182729,
                    60.47215680375073,
                ],
                'id':       '93703320-0e3d-41b3-89dd-7d81e7cc401b',
                'name':     'Памятник «Памяти комсомольцев, погибших в 1919 году»',
                'place':    'с. Краснополье',
                'desc':     'Памятник установлен в 1969 году, на месте боя красногвардейского отряда добровольцев села Краснополье с белогвардейскими отрядами адмирала Колчака.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167cef942d8b24dbebaaf47',
                'coords':   [
                    57.69945118740362,
                    60.36183474871823,
                ],
                'id':       'e456737d-d1f5-49ef-bbee-db0f8aa6cfc8',
                'name':     'Братская могила',
                'place':    'д. Реши',
                'desc':     'Монумент над братской могилой жителей деревни стоит в районе улиц Октябрьской и Южной, не имея\n никаких опознавательных знаков. Двадцать два жителя деревни были расстреляны в 1919 году\n солдатами армии Колчака и похоронены на этом \nместе. Во время своего отступления в Сибирь \nбелогвардейцы взрывали мосты и заводы, сжигали \nдеревни, попадавшиеся на пути, расстреливали \nместных жителей.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d12642d8b24dbebaaf48',
                'coords':   [
                    57.712611183177394,
                    60.57120932079601,
                ],
                'id':       'cc71f9da-07c2-46aa-a41a-3132291295f8',
                'name':     'База отдыха «Чистая заводь»',
                'place':    'д. Тёмно-Осинова',
                'desc':     'База используется для проживания в любое время года и на любой срок для компаний друзей, трудовых коллективов, семей с детьми, для проведения \nсвадеб, юбилеев и праздников, массовых культурных и спортивно - оздоровительных мероприятий. \nОсновное направление – комфортный отдых вдали \nот городского шума на берегу реки Нейва.\n+79221024144',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d23e42d8b24dbebaaf49',
                'coords':   [
                    57.78181706682834,
                    60.05936549999993,
                ],
                'id':       'd8899551-d236-4207-a958-0a320b2834a7',
                'name':     'Храм во имя Святителя Николая',
                'place':    'с. Николо-Павловское, ул. 8 марта, 68',
                'desc':     'По благословению архиепископа Пермского и Вер\nхотурского Антония, храм был заложен в сентябре \n1871г. Построен из материалов, безвозмездно уступленных заводовладельцами Демидовыми. Через \nгод и три месяца храм был построен, и в 1872 году \n3 декабря был освящён благочинным протоиереем \nАлексеем Карпинским во имя Святителя и Чудотворца Николая, архиепископа Мир Ликийского. В храме сохранились часть настенных росписей, старинные иконы, необычайно красивый иконостас.\n+73435915433',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d27b42d8b24dbebaaf4a',
                'coords':   [
                    57.77222856683413,
                    60.063785499999945,
                ],
                'id':       'e662c4ee-89c5-4a8d-ae2f-b69569536343',
                'name':     'Частный зоопарк «GreenPoll»',
                'place':    'с. Николо-Павловское, ул. Сосновая, 24',
                'desc':     'В коллекции зоопарка: рысь, еноты, лисы, барсуки,\nкуницы, хори, соболь, северный олень косуля, \nпони, ослик, карликовая коза, павлины, фазаны и \nдругие животные и птицы. Коллекция постоянно \nпополняется. Специально для самых маленьких \nесть зона контактного зоопарка, где можно поиграть\n и сфотографироваться с хорьками и морскими \nсвинками, покормить чёрным хлебом оленя и миниатюрную козочку.\n+79502006557',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d2ca42d8b24dbebaaf4b',
                'coords':   [
                    57.80269686118388,
                    60.043882289688035,
                ],
                'id':       'd691d540-d4b7-4346-91c4-518e904b4cf4',
                'name':     'Мемориальный комплекс, посвященный войнам, умершим от ран в госпитале  №1714 с 1942 по 1946 г. «Мемориал Руш»',
                'place':    'п. Отрадный, 33',
                'desc':     'Крупнейшее на Урале воинское захоронение времен \nВеликой Отечественной войны. На мемориале захоронены воины, сражавшиеся за Победу и умершие \nот ран в госпитале. Первый памятник воинам был \nустановлен в 1948 г. В 1975 г. на его месте открыли \nмемориальный комплекс. В 2020 году «Мемориал \nРуш» вновь открыт после масштабного восстановления. На пилонах братской могилы увековечено \n714 имен советских солдат.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d30d42d8b24dbebaaf4c',
                'coords':   [
                    57.74283031103802,
                    60.08530900000003,
                ],
                'id':       'b301cd2b-5ec9-49e6-9900-173a7c78ff6f',
                'name':     'Санаторий-профилакторий  «Ленёвка»',
                'place':    'п. Ленёвка',
                'desc':     'Расположен в живописном уголке Уральского края на берегу Ленёвского водохранилища. Удаленность \nот населённых пунктов и чистый воздух создают \nблагоприятные условия для отдыха и оздоровления\n взрослых и детей. Лечебно - оздоровительный корпус включает медицинские кабинеты, аквапарк, сауну, спортивный и тренажерный залы, салон красоты, фитобар.  Эффективное лечение заболеваний сердечно - сосудистой системы, органов дыхания,\nопорно-двигательного аппарата, нервной системы.\n+73435497400, +7343549742',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d33942d8b24dbebaaf4d',
                'coords':   [
                    57.69913,
                    60.19766,
                ],
                'id':       '5fb89400-c7cc-446a-b46d-bbcc586bbbd7',
                'name':     'Карьеры «Никель» («Голубые озера»)',
                'place':    'д. Анатольская',
                'desc':     'Карьеры возникли на склонах Грушевых гор. Анатольское месторождение комплексных железо-и никельсодержащих руд открыли в конце 1920-х годов, а разработку вели с 1944 года, когда Режевской никелевый завод испытывал так называемый \n«рудный голод». Добывали не только никель, но и \nкобальт. К концу 1950-х годов запасы месторождения иссякли, рудник закрылся, а карьеры постепенно заполнились водой. Глубина, по некоторым данным, достигает 26 м. В карьерах можно увидеть \nмальков рыбы и даже тритонов.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d38042d8b24dbebaaf4e',
                'coords':   [
                    57.69277122675889,
                    60.110188265818756,
                ],
                'id':       'd94bc436-a5a0-4cc1-b6db-68ca53dfeca0',
                'name':     'Водонапорная башня',
                'place':    'ст. Анатольская',
                'desc':     'В начале XIX века на Невьянском тракте Н.Н. Демидов основал деревню, которую назвал Анатольской в честь своего сына Анатолия. А поскольку \nона находилась на границе двух горных округов, \nто к ней добавилось второе название - Грань.\nКогда строили Горнозаводскую железную дорогу, \nодну из станций назвали по имени близлежащего \nнаселенного пункта Анатольской. С тех времен на \nстанции можно увидеть старую водонапорную \nбашню, чудом сохранившуюся на станции Анатольская с конца XIX века.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d3ab42d8b24dbebaaf4f',
                'coords':   [
                    57.760876,
                    60.188595,
                ],
                'id':       'e5ffb616-36e5-408d-934e-33bb3de5cdc3',
                'name':     'Святой источник Богородничный ключ',
                'place':    'с. Шиловка',
                'desc':     'Издревле источник, находящийся на территории храма Святой Троицы и Воскресения Господня, почитался в селе как святой источник, страждущие ходили туда за исцелением при любых болезнях и немощах своих, многие возвращались с облегчением страданий. Но в 1934 году, когда власть запретила богослужения в храме, источник вдруг \nскрылся под землей. Немало экспедиций провели в Шиловке, пока, наконец, в 2001 г., не нашли это \nсвятое место.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d3e642d8b24dbebaaf50',
                'coords':   [
                    57.77407656683896,
                    60.17762899999999,
                ],
                'id':       '3a347e25-90c4-4564-8be1-f5daa49e46eb',
                'name':     'Храм во имя Святой Троицы и Воскре сения Господня',
                'place':    'с. Шиловка, ул. Ленина, 36',
                'desc':     'Старый храм, расположенный в северной части села Шиловка. Он был построен в 1879-1884 годах в стиле эклектики. К основному объёму Троицкого храма, представляющему из себя неравногранный восьмиугольник под невысокой шатровой кровлей, в начале ХX века были пристроены колокольня и двухпридельная трапезная. В 1941 году церковь была закрыта, а её венчания сломаны. Помещение\nхрама власти отдали под производственные нужды.\n+79022617878',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d41842d8b24dbebaaf51',
                'coords':   [
                    57.74399500647751,
                    60.29545439573011,
                ],
                'id':       'e71fd020-a7d4-4805-b70f-955ca2568893',
                'name':     'Гора «Казачка»',
                'place':    'п. Новоасбест, переулок Горный',
                'desc':     'По преданию, когда-то появился здесь беглый казак Григорий Тарасов. От кого бежал, неизвестно, \nно для строительства выбрал он место на высокой\nгоре. С той поры эта гора Казачкой называется. В \nсоветское время (1965 год) здесь была построена \nводонапорная башня. Её высота составляет 25 \nметров и просматривается на расстоянии 10 км. \nКазачка является символом поселка. Долгие годы \nэта территория была местом отдыха жителей, где проводились массовые гуляния.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d44542d8b24dbebaaf52',
                'coords':   [
                    57.7385665668695,
                    60.295191499999994,
                ],
                'id':       'f4820727-1a51-4313-8703-32532bc47372',
                'name':     'Обелиск «Памяти павших в годы Великой Отечественной войны»',
                'place':    'п. Новоасбест, ул. 1-Тагильская',
                'desc':     'Облицованный мрамором обелиск открыли 6 ноября 1967 года, накануне пятидесятилетия празднования Великой Октябрьской социалистической \nреволюции. Первый митинг новоасбестовцев у памятника состоялся 9 мая 1968 года. В 1980 году, \nосенью было сделано панно памяти. В 2019 году \nвозведен новый обелиск. Сохранены место расположения и форма обелиска. На панно перечислены имена жителей посёлка - участников Великой \nОтечественной войны.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d50e42d8b24dbebaaf53',
                'coords':   [
                    57.75725390158327,
                    60.3057546243286,
                ],
                'id':       '40d07cd7-2625-48ac-96f4-83584ea9f7db',
                'name':     'Стрелковый стенд «Долгий мыс»',
                'place':    'п. Новоасбест, ул. Вилюйская, 76А',
                'desc':     'Стрелковый стенд является электрифицированным объектом со стандартной площадкой «круглый стенд» и «спортинг-компакт», с возможностью стрельбы по мишеням метательной установки «мультитрап». На объекте оказываются услуги: пуск \nмишеней под выстрел; предоставление в аренду \nзакрытой и открытой веранд (с мангальной зоной) \nдля организации мероприятий на свежем воздухе. ',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d53842d8b24dbebaaf54',
                'coords':   [
                    57.69913,
                    60.19766,
                ],
                'id':       '858c958b-f6fe-44dc-93d1-9812897269c5',
                'name':     'Карьер «Лазурный»',
                'place':    'п. Новоасбест',
                'desc':     'Лазурный карьер привлекает, прежде всего, любителей дайвинга, так как он действительно является \nуникальным, будто специально созданным для \nпогружений под воду. Дайверы называют его лучшим местом для тренировок и подводного плавания, тем более, что приезжать сюда можно круглый \nгод, а нырять - и днем, и ночью. Здесь находится\n дайв-лагерь с прокатом снаряжения, где можно \nпройти обучение у инструктора международного класса.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d55c42d8b24dbebaaf55',
                'coords':   [
                    57.7446,
                    60.20591,
                ],
                'id':       'd22e1408-94d9-43f8-acbc-26ae404b7795',
                'name':     'Карьер «Хургада»',
                'place':    'п. Новоасбест',
                'desc':     'До 1992 года в этом месте добывали голубой асбест, но добычу асбеста прекратили в связи с отсутствием потребителя. Месторождение было законсервировано путём полного затапливания карьера. Карьер Хургада (официальное название 1-й Шиловский) - самый большой по размерам: до 650х670 метров. Данные о глубине этого карьера\nразнятся: по одним данным она составляет лишь \n30 метров, по другим - более 100 метров.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d58a42d8b24dbebaaf56',
                'coords':   [
                    57.565305426879426,
                    60.88803940213003,
                ],
                'id':       '1eae047d-272b-4de2-b934-c47c9e281996',
                'name':     'Храм в честь Вознесения Господня',
                'place':    'с. Новопаньшино, ул. Калинина, 26Б',
                'desc':     'В 1854 году был построен каменный храм и освящен придел в честь Покрова Пресвятой Богородицы. В 1869 году был освящён и главный двухпрестольный храм во имя Вознесения Господня. Церковь была закрыта в 1934 году, в здании размещался склад. Колокольня не сохранилась. В настоящее время церковь восстановлена на пожертвования местных жителей, идут службы.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167d5c342d8b24dbebaaf57',
                'coords':   [
                    57.57124419055454,
                    60.88643938860049,
                ],
                'id':       'd388ac9a-0b1f-4434-8c43-d5a4021c8e3d',
                'name':     'Обелиск «Воинам, погибшим в годы Великой Отечественной войны»',
                'place':    'с. Новопаньшино, ул. Калинина',
                'desc':     'Обелиск установлен в 1970 году. На плите значится\n 170 имен жителей села и бывшего Паньшинского \nсельского сельсовета, участвовавших в Великой \nОтечественной войне.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167dec942d8b24dbebaaf58',
                'coords':   [
                    57.71064208345272,
                    60.64843049143213,
                ],
                'id':       'd24ffef5-4e12-4f4a-8a25-993f70a6ed89',
                'name':     'Демидовская заводская плотина',
                'place':    'с. Петрокаменское  ',
                'desc':     'Петрокаменский пруд был образован в конце XVIII\n века на реке Нейва для обеспечения бесперебойной работы Петрокаменского чугуноплавильного \nзавода. Вместе с заводом всего за два года была \nпостроена плотина. Завод сыграл немаловажную \nроль в создании большого, развитого села. В 1905\nгоду произошло наводнение, и часть плотины \nбыла снесена. В 1918 году жителю села пришла \nидея восстановить плотину и построить там ГЭС. ',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e11f42d8b24dbebaaf59',
                'coords':   [
                    57.70752028141491,
                    60.65526476000208,
                ],
                'id':       '5eeaafe5-f25f-4258-9e8c-fc466601bebe',
                'name':     'Стадион «Юпитер»',
                'place':    'с. Петрокаменское, ул. Бебеля, 1А',
                'desc':     'Стадион был построен в 1964 году и включал в себя: \nбеговую дорожку, футбольное поле, четыре волей-\nбольных и одну баскетбольную площадку, трибуны \nи раздевалки. Сейчас здесь обустроена современная площадка для занятий уличной гимнастикой, заливается ледовая площадка, выдаются \nна прокат коньки. Построено здание с раздевалками, душевыми, тренерской, медкабинетом, помещением для приема пищи, тренажерным залом, \nкомнатой для хранения инвентаря.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e14e42d8b24dbebaaf5a',
                'coords':   [
                    57.713518066896505,
                    60.65219099999998,
                ],
                'id':       '971a757d-cf72-4a33-839a-7abe04cb273e',
                'name':     'Петрокаменский центр культуры',
                'place':    'с. Петрокаменское, ул. Почтовая, 1Е',
                'desc':     'Петрокаменский центр культуры является уникальным учреждением культуры и спорта, созданным \nпо экспериментальному проекту в 1989 году.\n+73435930233',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e1e242d8b24dbebaaf5b',
                'coords':   [
                    58.01319079984384,
                    60.32794927928478,
                ],
                'id':       '88ad20d8-018b-461f-91e0-aac23503c46c',
                'name':     'Захоронение узников спецотряда  № 18-75 «Тагиллага»',
                'place':    'Бывшая деревня Каменка в 10-12 км  северо-восточнее пос. Молодежный',
                'desc':     'Спецотряд в Каменке был создан в 1942 году из советских немцев. Отряд был отправлен на лесоповал\n и строительство узкоколейной железной дороги для\n вывоза леса. К 1 августа 1942 года на Каменке находилось около четырёх тысяч немцев. Многие из \nних погибли в этих местах от голода и непосильного\n труда на заготовке древесины. Захоронение включено \nв перечень выявленных объектов культурного наследия Свердловской области.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e20d42d8b24dbebaaf5c',
                'coords':   [
                    57.96922656660827,
                    60.26080399999989,
                ],
                'id':       'ae85f9b7-12fc-4391-aa9d-b308bc07df52',
                'name':     'Храм во имя Покрова Пресвятой  Богородицы',
                'place':    'с. Покровское, ул. Советская, 83А',
                'desc':     'Хотя село и меняло названия, но церковь здесь\nвсегда была Покровской. Сначала - деревянная \nСвято-Никольская церковь, освященная в 1856 г., \nзатем - каменная, которая была построена в 1908 г.\nи освящена в честь Покрова Пресвятой Богородицы.\n Каменный, однопрестольный храм выполнен в русско-византийском стиле и имеет форму креста. Из множества икон особенно выделяется одна - Образ Пресвятой Богородицы «Благодатное небо».',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e2c242d8b24dbebaaf5d',
                'coords':   [
                    57.96777544632027,
                    60.25981501705339,
                ],
                'id':       '624a2b6a-98ce-4ced-8c91-33371d638f96',
                'name':     'Мемориальный комплекс «Воинам, погибшим в годы Великой Отечественной войны»',
                'place':    'с. Покровское, ул. Советская',
                'desc':     'В преддверии празднования 75-й годовщины Победы \nв Великой Отечественной войне 14 октября 2020 г.\nна месте старого памятника, установленного в 1975 \nгоду, открыт мемориальный комплекс. Центральная \nчасть мемориала посвящена покровчанам, воевавшим в годы Великой Отечественной войны. Правая часть покровчанам - труженикам тыла, вдовам и \nдетям войны. Левая часть - всем ветеранам боевых действий современных войн.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e2fa42d8b24dbebaaf5e',
                'coords':   [
                    57.96777389940587,
                    60.257419084655645,
                ],
                'id':       '161b8c52-eb4b-4b84-8557-9616fab7bfb7',
                'name':     'Колодец «Казенка»',
                'place':    'с. Покровское, перекресток ул. Советская и ул. Симбирская',
                'desc':     'В 18 веке по царскому указу был построен «Казенный» колодец Верхотурского уезда, прозванный в \nнароде «Казенка». Ямщики, отправившись на Ирбитскую ярмарку по тракту Нижний Тагил – Алапаевск, могли отдохнуть, утолить жажду и напоить \nлошадей. Царский колодец был построен основательно, так как строили в старину. Реконструкция \nколодца прошла только в 2016 году. Колодец был построен глубиной 18-20 м, а после реконструкции \nего глубина стала 30 м.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e32742d8b24dbebaaf5f',
                'coords':   [
                    57.890985,
                    59.470821,
                ],
                'id':       'b6cefbea-49ec-4f33-a772-e5bec61a1050',
                'name':     'Гора Синяя',
                'place':    'п. Синегорский, скалы с видом на поселок',
                'desc':     'Невысокий, но живописный хребет со скальными останцами. Протяженность с севера на юг 28 км. Главная вершина хребта - Синяя гора, высота 600 м. \nОна находится примерно в 11 км. справа от дороги \nна п. Серебрянка. По гребню хребта встречаются \nживописные, причудливые скальные останцы. Ближайшие к поселку находятся у перевала Синяя \nгора - скалы Столб и Каменные ворота, а несколько \nюжнее – скала Кораблик. С вершины скал открывается вид на посёлок Синегорский.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e35242d8b24dbebaaf60',
                'coords':   [
                    57.895306,
                    59.564889,
                ],
                'id':       '4afb8041-9f12-49d4-982b-f2ce89fb9bb4',
                'name':     'Стела Европа-Азия',
                'place':    'п. Синегорский',
                'desc':     'Памятник представляет собой высокую, девятиметровую стелу из листовой стали. Ее венчают символы СССР – серп и молот. С восточной стороны нанесена надпись «Азия», а с западной «Европа». \nСоздана по проекту А.А. Шмидта и установлена рабочими леспромхоза Синегорский в 1967 году - в \nчесть 50-летия Великого Октября. Стела расположена у дороги Нижний Тагил - Серебрянка на пере\nвале Большой Урал недалеко от п. Синегорский, который хорошо отсюда просматривается.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e3d442d8b24dbebaaf61',
                'coords':   [
                    57.72928966676726,
                    59.876494768111556,
                ],
                'id':       '0d6f54c0-1ade-47cf-b7bb-321cf11e3197',
                'name':     'Черноисточинский пруд с Ушковской  канавой и окружающими лесами',
                'place':    'п. Черноисточинск',
                'desc':     'В результате строительства заводской плотины и железоделательного завода в 1726-1729 годах на реке Черный Исток образован Черноисточинский пруд. В 1849 году построен канал от реки Черной. Черноисточинский пруд дополнительно пополнился водой и принял современные очертания. \nЯвляется самым крупным в области искусственным \nводоёмом. Имеет статус объекта культурного наследия федерального значения.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e3f442d8b24dbebaaf62',
                'coords':   [
                    57.770983,
                    59.860017,
                ],
                'id':       'c8f45846-b9e2-4500-9b0a-0448bbb415ea',
                'name':     'Гора Дыроватик',
                'place':    'В 3 километрах севернее п. Черноисточинск',
                'desc':     'Гора Дыроватик, расположенная в окрестностях пос. \nЧерноисточинск, получила свое название за необычное сквозное отверстие в скале на вершине. Гора\nявляется геоморфологическим памятником природы. \nИмеет оригинальную форму рельефа на вершине \nгоры в виде огромной арки. Со скал, возвышающихся над лесом, в хорошую погоду открывается потрясающий вид на Черноисточинск, Черноисточинский пруд, гору Липовую и окрестности.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e42442d8b24dbebaaf63',
                'coords':   [
                    57.73069106687978,
                    59.87655849999996,
                ],
                'id':       'e91a0823-eeb8-4cf1-a9de-e529d9da6e8c',
                'name':     'АртРезиденция «Щерный квадрат»',
                'place':    'п. Черноисточинск, ул. Кирова, 2А ',
                'desc':     'Завод, построенный в Черноисточинске в 1726 году, \nпревратился в творческое пространство с мастерскими, галереей, арт-магазином и коворкингом для\nхудожников. АртРезиденция - творческое пространство для местных и приглашённых из разных угол\nков мира художников. Также здесь расположился \nмузей наличников - «Вычурны балясины». Некоторые из резных домовых украшений получили вторую жизнь – превратились в зеркало, стеллаж и театральные кулисы.\nart.sofp.ru',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e49942d8b24dbebaaf64',
                'coords':   [
                    57.731849066882695,
                    59.87552550000002,
                ],
                'id':       'a56a61c5-4f03-4554-8fa5-971d3af46b91',
                'name':     'Музей истории поселка Черноисточинск',
                'place':    'п. Черноисточинск, ул. Юбилейная, 2А',
                'desc':     'За годы существования поселка черноисточинцы прошли славный трудовой путь и с гордостью хранят наследие прошлого в краеведческом музее. В\nмузее представлена экспозиция, посвященная старообрядцам - самым первым поселенцам, давшим начало посёлку. Другая часть экспозиции рассказывает \nоб истории железоделательного завода, который основал здесь Акинфий Демидов. Широко представ-\nлен в музее быт сельчан конца 19-го - начала 20-го \nвеков.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e4d242d8b24dbebaaf65',
                'coords':   [
                    57.73750655516207,
                    59.878988319465606,
                ],
                'id':       '4dd88abd-9d06-4367-812b-cbf0849977be',
                'name':     'Петро-Павловский храм',
                'place':    'п. Черноисточинск, переулок Апостолов Петра и Павла, 1',
                'desc':     'Нынешний каменный Петро-Павловский храм был заложен 17 сентября 1856 года по благословению архиепископа Пермского и Верхотурского Неофита. Построен храм иждивением заводовладельцев \nДемидовых. Освящён 28 октября 1862 года. В 1941 \nгоду храм был закрыт, а в 1944 году передан единоверческой общине и больше не закрывался.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e50a42d8b24dbebaaf66',
                'coords':   [
                    57.73000717168251,
                    59.876135300018774,
                ],
                'id':       'ce9216a0-f2d6-4187-a31b-67a9dc518126',
                'name':     'Обелиск «Воинам-землякам, погибшим в Великую Отечественную войну 1941- 1945 гг»',
                'place':    'п. Черноисточинск, центральная площадь на берегу пруда',
                'desc':     'В 1967 году на берегу Черноисточинского пруда, при\nслиянии трех улиц, черноисточинцы установили обелиск воинам, погибшим в Великую Отечественную войну. 1572 человека ушли на фронт, половина\nиз них не вернулась.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e52a42d8b24dbebaaf67',
                'coords':   [
                    57.63365,
                    61.036583,
                ],
                'id':       'b5b367e0-2805-4258-971c-7e53f393fc47',
                'name':     'Южаковский водопад на реке Ямбарка',
                'place':    'с. Южаково',
                'desc':     'Южаковские водопады - излюбленное место отдыха\nне только жителей окрестных деревень, но и горожан. Водопад здесь образовался в 1970-е годы из-за прорыва плотины пруда, созданного для полива \nполей. В результате была частично размыта насыпь.\nВода падает с горных обнажений, образуя живописный водопад.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e63042d8b24dbebaaf68',
                'coords':   [
                    57.6877635669217,
                    61.023913499999935,
                ],
                'id':       '8c1c90ef-c7d4-4bc9-bd51-0cda790153af',
                'name':     'Минералогический музей имени А.Е. Ферсмана',
                'place':    'с. Мурзинка, ул. Декабристов, 14А',
                'desc':     'Музей создан в 1964 году. Основатель музея – Иван\n Иванович Зверев - горщик, геолог, рудознатец. Внук\n Данилы Кондратьевича Зверева - Данилы-мастера \nиз сказов Бажова. Расположен музей в здании Сретенской церкви, которая является объектом культурного наследия регионального значения. В музее\nможно увидеть образцы редких, необычных, порой \nуникальных минералов со всего мира, узнать историю образования, рассмотреть поближе мельчайшие кристаллы.\n+73435931721\nmk-ggo.ru',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e67442d8b24dbebaaf69',
                'coords':   [
                    57.68922990578024,
                    61.02235168920715,
                ],
                'id':       '6a8c8f0b-3b38-4615-bc9d-b04ca6b6d146',
                'name':     'Тальяновские копи',
                'place':    'в 2 км от с. Мурзинка',
                'desc':     'Геологический, исторический памятник природы. Старинные самоцветные копи среднеуральской добычи драгоценных камней 18-19 веков. Копь Тальян, которая находится непосредственно на северной окраине села - одна из самых известных аметистовых копей Мурзинского района. По сей день можно увидеть шурфы, в которых и сотню лет назад добывались знаменитые по всему миру Мурзинские самоцветы! Здесь добывали берилл, топаз, \nтурмалин, аметист, морион, кордиерит, корунд. ',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e6ae42d8b24dbebaaf6a',
                'coords':   [
                    57.56286677370796,
                    61.06254227091211,
                ],
                'id':       '0101fe7d-e068-4008-837b-5e7b8b074b8c',
                'name':     'Храм во имя святой великомученицы Параскевы Пятницы',
                'place':    'с. Кайгородское, ул. Советская, 19',
                'desc':     'В 1900 году была заложена каменная, однопрестольная церковь, которая была освящена во имя \nвеликомученицы Параскевы 14 октября 1913 года.\n В 1940-м храм закрыли, а возвратили для богослужений только через 55 лет. К настоящему времени колокольня, декор и росписи в храме не со\nхранились, фасады здания декорированы фигур\nной кирпичной кладкой.',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e6d142d8b24dbebaaf6b',
                'coords':   [
                    57.56125306705295,
                    61.05283049999995,
                ],
                'id':       '0e4ca18c-2469-4c21-8c6c-f9b3150e9f55',
                'name':     'Музейная комната Кайгородского дома культуры',
                'place':    'с. Кайгородское, ул. Ленина, 103А',
                'desc':     'Музейная комната разбита на 4 экспозиции, куда входят экспонаты 17-20в. Посетив экскурсию, можно \nузнать о том, как здесь жили люди, чем занимались, \nисторию самого села. Каждый предмет можно по-\nдержать в руках и опробовать его в действии. А еще\n попробовать себя в умении ручного прядения или \nвязания. О роли самоцветов в жизни населения, о \nкопях и самоцветах, которые добывались на кайгородской земле, рассказывает  экспозиция «Кайгородские самоцветы».',
                'isActive': true,
                'img':      '',
            },
            {
                '_id':      '6167e6fc42d8b24dbebaaf6c',
                'coords':   [
                    57.56137356708372,
                    61.051554999999986,
                ],
                'id':       'fdda6fd3-5756-4f99-b47f-b0add614f335',
                'name':     'Дерево «Бонсай»',
                'place':    'с. Кайгородское, ул. Советская, 19',
                'desc':     'По пути к храму на холме Тальян, встречается красивая, раскидистая сосна, возраст которой более 300\nлет. Многовековое дерево имеет необъятный ствол\nи шикарную крону, напоминающую миниатюрные деревья, выращенные в стиле бонсай. Возвышаясь \nна холме, сосна является негласным символом села Кайгородское. Как и в давние времена, сюда приходят, чтобы полюбоваться видами села.',
                'isActive': true,
                'img':      '',
            },
        ];
        
        return {
            data: data,
        };
    },
};
</script>

<style scoped>

</style>
